<ion-app>
  <div *ngIf="trial?.success">
    <ion-row class="plan" *ngIf="trial?.success  && role == 'account_owner'" (click)="openUpgrade()">
      <p class="info"  *ngIf="trial?.success && trial?.trial>0 "> <span style="font-family: 'Poppins-bold';"> Your Pro Plan Trial Ends in  {{ trial?.trial }} Days </span> <span class="bold underline" > Upgrade now</span>
        </p> 
        <p class="info"  *ngIf="trial?.success && trial?.trial<=0 && plan[0].plan_name=='Free' "  style="font-family: 'Poppins-bold';">Your trial  has ended <span class="bold underline" > Upgrade now</span>
        </p>
    </ion-row>
  </div>
 
  <ion-router-outlet [class.pad]="trial?.success  && role == 'account_owner'"></ion-router-outlet>
</ion-app>
