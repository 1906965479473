import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, from, of, throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Preferences } from '@capacitor/preferences';
import OneSignal from "onesignal-cordova-plugin";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  
  checkUserObs:Observable<any>;
  
  
  constructor(
    private readonly platform:Platform,private router : Router, private http : HttpClient,
  ) {
    
    this.loadUserInfo();
  
    
    
    
  }
 
  // GUARD OBSERVAL
  loadUserInfo() {
   
    let readyPlatformObs = from(this.platform.ready());
 
    this.checkUserObs = readyPlatformObs.pipe(
      switchMap(() => {
          return from(this.getAccessToke());
      }),
      map((token) => {
        if(!token){
          return null;
        } 
          return true;
      }));
    
  }


  // Access Token
  getAccessToke(){
    return this.checkToken();

  }
  setToken = async (token) => {
    await Preferences.set({
      key: 'access_token',
      value: token.toString(),
    });
  };
  checkToken = async () => {
    const { value } = await Preferences.get({ key: 'access_token' });
    return value;
  };
  removeToken = async () => {
    await Preferences.remove({ key: 'access_token' });
  };

  // UserInfo
  setUserInfo = async (user) => {
    await Preferences.set({
      key: 'userInfo',
      value: user.toString(),
    });
  };
  getUserInfo = async () => {
    const { value } = await Preferences.get({ key: 'userInfo' });
    return JSON.parse(value);
  };

  //AUTH

    useLogin(login: any): Observable<boolean> {
    if (login && login.email && login.password) {
      var payload={
        email:login.email,
        password:login.password
        };
        return this.http.post(environment.url + "/auth/login",payload).pipe(
        map( (response:any)=>{
          this.setToken(response.access_token)
          
          this.platform.ready().then(() => {
            if(this.platform.is('cordova')){
              OneSignal.login(response.user.id)
            }
            
          });
          
          this.setUserInfo( JSON.stringify(response.user))
          this.setActiveClient( response.user.activeclient)
          return true;
        })
        )
    
    
    }
    return of(false);
   }

   // getAccountInfos


    setAccountInfo = async (account) => {
      await Preferences.set({
        key: 'accountInfo',
        value: account.toString(),
      });
    };
    getAccountInfo = async () => {
      const { value } = await Preferences.get({ key: 'accountInfo' });
      return JSON.parse(value);
    };

    // Set active Client

    setActiveClient = async (client) => {
      await Preferences.set({
        key: 'getActiveClient',
        value: client.toString(),
      });
    };

   


   useRegister(register: any): Observable<boolean> {
    if (register && register.email && register.password && register.phone && register.name) {
      var payload={
        email:register.email,
        name:register.name,
        password:register.password,
        phone:register.phone
        };
        return this.http.post(environment.url + "/auth/register",payload).pipe(
        map((response:any)=>{
          this.setToken(response.access_token)
          this.setActiveClient( response.user.activeclient)
          this.platform.ready().then(() => {
            if(this.platform.is('cordova')){
              OneSignal.login(response.user.id)
            }
            
          });
          this.setUserInfo( JSON.stringify(response.user))
          return true;
        })
        )
    
    
    }
    return of(false);
   }

   async useLogout() {
    
      await Preferences.remove({ key: 'access_token' });
      await Preferences.remove({ key: 'userInfo' });
      this.platform.ready().then(() => {
        if(this.platform.is('cordova')){
          OneSignal.logout()
        }
        
      });
      this.router.navigateByUrl('/login');

  
  }
}