import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';

import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AuthService} from './services/auth.service';
import { appInitializer } from './_helpers/app.initializer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OneSignal } from '@ionic-native/onesignal/ngx';

declare global {
  interface Window {
    Paddle?: any;
    Calendly?: any;
  }
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,HttpClientModule, IonicModule.forRoot(), AppRoutingModule, NgbModule  
  ],
  providers: [{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true},{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy, },AuthService,OneSignal],
  bootstrap: [AppComponent],
  
})
export class AppModule {}
