import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { GeneralService } from '../services/general.service';
import { SettingsService } from '../services/settings.service';
import { Preferences } from '@capacitor/preferences';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.page.html',
  styleUrls: ['./upgrade.page.scss'],
})
export class UpgradePage implements OnInit {

  user : any;
  members: any;
  userSup: any;
  displayPlans: any;
  limitIndex: any=0;
  constructor(private router : Router,private modalController: ModalController,private auth : AuthService,private general : GeneralService,private settings:SettingsService) { }
  clients : any;
  selectedClient : number;
  items = [];
  options: {};
  page: number = 1;
  accountInfo: any;
  trial:any;
  plans : any;
  leadLimits = [];
  leadLimit = '5000';
  annual = false;
  activePlanMonth = 'active';
  activePlanYear = '';
  
  @Input() trialEnded:any;
  @Input() retour:any
  
thankyou = false;
  success=false;
  async ionViewWillEnter() {
    // User info
    this.getActiveClient().then(async (value)=>{
   
      this.selectedClient = value
      
      //general infos
      await this.getUser();
      await this.getTrial();
      await this.getPlans();
     
    }) 

    this.leadLimits =['5000','10000','25000','50000','100000'];
  }

  //activated Client

  getActiveClient = async () => {
    const { value } = await Preferences.get({ key: 'getActiveClient' });
    return parseInt(value);
  };
  
  setActiveClient = async (client) => {
    await Preferences.set({
      key: 'getActiveClient',
      value: client.toString(),
    });
  };

  async getTeamMembers(){
    return (await this.settings.getTeamMembers(this.selectedClient)).subscribe(async (res: {}) => {
      this.members =  res;
      this.userSup = this.members?.members?.filter(e => e.id == this.user?.user?.id)
      this.userSup = this.userSup[0]
    });
  }

  back(){
    if(this.retour && this.retour =="leads"){

        this.modalController.dismiss(null, 'cancel');
      
    }else{
      this.router.navigateByUrl("tabs/settings")
    }
  }
  async getClients(){
    return (await this.general.getClients()).subscribe((res: {}) => {
      this.clients =  res;
      this.clients =  Object.values(this.clients?.data?.clients);
    });
  }

  async getAccountInfo(){
    return (await this.general.getAccountInfo(this.selectedClient)).subscribe((res: {}) => {
      this.accountInfo = res;
      this.changeClient();
    });
  }
  changeClient(){
    
    this.setActiveClient(this.selectedClient).then((value)=>{
  
    })
    
  }
  onClientChange(){
    
    this.setActiveClient(this.selectedClient).then((value)=>{
      
    })
      
   
    
  }

  ngOnInit() {
    
  }
  async getUser(){
    return (await this.general.getUser(this.selectedClient)).subscribe((res: {}) => {
      this.user = res;
    });
    
  }

  async getTrial(){
    return (await this.general.getTrial(this.selectedClient)).subscribe((res: {}) => {
      this.trial = res;
    });
    
  }

  async getPlans(){
    return (await this.general.getPlansPro(this.selectedClient)).subscribe((res: {}) => {
      this.plans = res;
      this.displayPlans = this.plans.plans.filter(i => i.name == "Pro" && i.plan_type == 'month' && i.total_leads == '5000');
    });
    
  }

  logout(){
    this.auth.useLogout();
  }
  minus(){
    if(this.limitIndex !=0){
    this.limitIndex =this.limitIndex -1;
    this.leadLimit = this.leadLimits[this.limitIndex]
    this.displayPlans = this.plans.plans.filter(i => i.name == "Pro" && i.plan_type == 'month' && i.total_leads == this.leadLimits[this.limitIndex]);
    
    }
  
  }
  plus(){
    if(this.limitIndex != 3){
      this.limitIndex =this.limitIndex +1;
      this.leadLimit = this.leadLimits[this.limitIndex]
      this.displayPlans = this.plans.plans.filter(i => i.name == "Pro" && i.plan_type == 'month' && i.total_leads == this.leadLimits[this.limitIndex]);

    }
   
  }
  getMonth(price){
    return (parseInt(price) / 12).toFixed(2)
  }
  getMonthPrice(price){
    return (parseInt(price) / 10).toFixed(2)
  }
  subscription(product_id){
    window.Paddle.Checkout.open({
      product			: product_id,
      passthrough		: this.user.user.email,
      email			: this.user.user.email,
      allowQuantity	: false,
      disableLogout:true,
      successCallback	: async function() {
        
        window.location.replace("upgrade/success");

        
      },
      closeCallback : async function() {
     
        window.location.replace("upgrade/error");

      },
    })
    
  }

  selectLeadLimit(e){
    this.limitIndex = e.detail.value;
    this.leadLimit = this.leadLimits[e.detail.value]
   
    if(this.annual){
      this.displayPlans = this.plans.plans.filter(i => i.name == "Pro" && i.plan_type == 'year' && i.total_leads == this.leadLimit);
    }else{
      this.displayPlans = this.plans.plans.filter(i => i.name == "Pro" &&  i.plan_type == 'month' && i.total_leads == this.leadLimit);
    }
  }
  
  getPlan(lim){
    this.limitIndex =lim;
    this.leadLimit = this.leadLimits[lim]
   
    if(this.annual){
      this.displayPlans = this.plans.plans.filter(i => i.name == "Pro" && i.plan_type == 'year' && i.total_leads == this.leadLimit);
    }else{
      this.displayPlans = this.plans.plans.filter(i => i.name == "Pro" &&  i.plan_type == 'month' && i.total_leads == this.leadLimit);
    }
  }

  selectAnnual(){
    if(this.annual){
      this.displayPlans = this.plans.plans.filter(i =>i.name == "Pro" &&  i.plan_type == 'year' && i.total_leads == this.leadLimit);
      this.activePlanMonth = "";
      this.activePlanYear = "active";
    }else{
      this.displayPlans = this.plans.plans.filter(i => i.plan_type == 'month' && i.total_leads == this.leadLimit);
      this.activePlanMonth = "active";
      this.activePlanYear = "";
    }
  }


  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }


  
}
