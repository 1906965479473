import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root",
})
export class GeneralService {
    _clients: any;

    
    
    constructor(
      private readonly platform:Platform,private router : Router, private http : HttpClient,private auth:AuthService
    ) {
     
    }
  

    async getClients(){
            const httpOptions =  {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + await this.auth.checkToken()

                })
              };
            return this.http.post<any>(environment.url + "/client/getclients",null,httpOptions );
            
    }

    

    async getAccountInfo(selectedClient){
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.get<any>(environment.url + "/get_user_info?client_id="+ selectedClient,httpOptions );
      
      
    }

    async getAnalytics(payload){
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.post<any>(environment.url + "/analytics",payload,httpOptions );
      
    }


    async saveAlert(payload,client_id,source_id){
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.patch<any>(environment.url + "/lead_alert/"+ source_id,payload,httpOptions );
      
    }

    async getUser(client_id){
      const httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + await this.auth.checkToken()

        })
      };
      return this.http.get<any>(environment.url + "/user?client_id="+ client_id,httpOptions );
    }


    

    async getTrial(client_id){
      let payload ={
        "client_id": client_id
      }
      const httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + await this.auth.checkToken()

        })
      };
      return this.http.post<any>(environment.url + "/trial",payload,httpOptions );
    }

    async getNavBar(client_id){
     
      const httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + await this.auth.checkToken()

        })
      };
      return this.http.get<any>(environment.url + "/nav_bar?client_id=" + client_id,httpOptions );
    }


    async getPlansPro(client_id){
      let payload ={
        "client_id": client_id
      }
      const httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + await this.auth.checkToken()

        })
      };
      return this.http.post<any>(environment.url + "/plan/listMobilePro",payload,httpOptions );
    }

    async getPlansSolo(client_id){
      let payload ={
        "client_id": client_id
      }
      const httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + await this.auth.checkToken()

        })
      };
      return this.http.post<any>(environment.url + "/plan/listMobileSolo",payload,httpOptions );
    }

    async saveWorkspace(payload){
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.post<any>(environment.url + "/client/createclient",payload,httpOptions );
      
    }

    

    async saveLead(payload){
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.post<any>(environment.url + "/leads/add_new_lead",payload,httpOptions );
      
    }

    

   
}