import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from "./auth.service";
@Injectable({
    providedIn: "root",
})
export class SettingsService {
    _clients: any;

    
    
    constructor(
      private readonly platform:Platform,private router : Router, private http : HttpClient,private auth:AuthService
    ) {
     
    }
    
    async addTemplate(body,client_id){
        let payload ={
            "title": body,
            "subject": body,
            "body": body,
            "type": "email",
            "client_id": client_id
        };
        const httpOptions =  {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + await this.auth.checkToken()
  
            })
          };
        return this.http.post<any>(environment.url + "/add_template",payload,httpOptions );
        
      }

      async deleteTemplate(id,client_id){
        let payload ={
            "client_id": client_id
        };
        const httpOptions =  {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + await this.auth.checkToken()
  
            }),
            body:payload
          };
        return this.http.delete<any>(environment.url + "/template/" + id,httpOptions );
        
      }


      async deleteSource(id,client_id){
        let payload ={
            "client_id": client_id,
            permissionid: 3,
            provider: "delete sources"
        };
        const httpOptions =  {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + await this.auth.checkToken()
  
            }),
            body:payload
          };
        return this.http.delete<any>(environment.url + "/sources/delete_source/" + id,httpOptions );
        
      }


      async deleteMember(id,client_id){
        let payload ={
            "client_id": client_id,
            permissionid: 6,
            provider: "delete users",
            team_id: client_id,
            user_id : id
        };
        const httpOptions =  {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + await this.auth.checkToken()
  
            }),
            body:payload
          };
        return this.http.post<any>(environment.url + "/teammember/removeMember/" + id,payload,httpOptions );
        
      }

      async deleteInvitation(client_id,token){
        let payload ={
            client_id: client_id,
            deny_token : token
        };
        const httpOptions =  {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + await this.auth.checkToken()
  
            }),
            body:payload
          };
        return this.http.post<any>(environment.url + "/teammember/cancelInvitation" ,payload,httpOptions );
        
      }
    
    
    async getTemplates(type,client_id){
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.get<any>(environment.url + "/templates?type=" + type + "&keyword=&client_id=" + client_id,httpOptions );
      
    }

    async getTeamMembers(client_id){
      let payload = {
        client_id : client_id
      }
      const httpOptions =  {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + await this.auth.checkToken()
  
        })
      };
      return this.http.post<any>(environment.url + "/teammember/teamMembers",payload,httpOptions );
      
    }
    
    async updateProfile(payload){
      
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.post<any>(environment.url + "/profile/updateuser",payload,httpOptions );
      
    }

    async updatePreferences(payload){
      
      const httpOptions =  {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + await this.auth.checkToken()

          })
        };
      return this.http.post<any>(environment.url + "/profile/updateuserpreferences",payload,httpOptions );
      
    }
   
}