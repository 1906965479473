import { Component } from '@angular/core';

import { SplashScreen } from '@capacitor/splash-screen'
import OneSignal from 'onesignal-cordova-plugin';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { GeneralService } from './services/general.service';
import { UpgradePage } from './upgrade/upgrade.page';
import { SettingsService } from './services/settings.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  selectedClient : number;
  accountInfo: any;
  trial:any = null;
  navbar: any;
  user:any;
  members:any;
  userSup:any;
  role:any
  plan: any;
  constructor(private platform: Platform,private alertCtrl: AlertController,private general : GeneralService,private modalController: ModalController,private settings:SettingsService,private router: Router) {
    this.initializeApp();
  }

  initializeApp() {

    /* To make sure we provide the fastest app loading experience
       for our users, hide the splash screen automatically
       when the app is ready to be used:

        https://capacitor.ionicframework.com/docs/apis/splash-screen#hiding-the-splash-screen
    */
    SplashScreen.hide();
    this.logout();
    this.platform.ready().then(() => {
      if(this.platform.is('cordova')){
        this.OneSignalInit();
      }
      
      });
      
    }

    ngOnInit() {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.getActiveClient().then(async (value)=>{
     
            this.selectedClient = value
            
            //general infos
            if(this.router.url != "/login" && this.router.url !="/register"){
              await this.getNavBar();        
              await this.getUser();
              await this.getTrial();
            }else{
              this.trial = null
              this.user = null
              
            }
            
            
          
    
           
          }) 
        }
      });

      
    }
    async ionViewWillEnter() {
      // User info
      
    }
    async getTeamMembers(){
      
      return (await this.settings.getTeamMembers(this.selectedClient)).subscribe(async (res: {}) => {
        
        this.members =  res;
        this.userSup = this.members?.members?.filter(e => e.id == this.user?.user?.id)
        this.userSup = this.userSup[0]
        
        this.role=this.userSup.role;
        
        await this.setRole();
      
        
        
      });
    }
    getActiveClient = async () => {
      const { value } = await Preferences.get({ key: 'getActiveClient' });
      return parseInt(value);
    };
    setRole = async () => {
      await Preferences.set({
        key: 'role',
        value: this.role,
      });
    };
   OneSignalInit() {
	// Uncomment to set OneSignal device logging to VERBOSE  
	// OneSignal.Debug.setLogLevel(6);
	
	// Uncomment to set OneSignal visual logging to VERBOSE  
	// OneSignal.Debug.setAlertLevel(6);
  
	// NOTE: Update the init value below with your OneSignal AppId.
	OneSignal.init("e6583c0d-a6c2-4efd-959e-8489bdcc4659");
	
	
	let myClickListener = async function(event) {
		  let notificationData = JSON.stringify(event);
	  };
	OneSignal.Notifications.addEventListener("click", myClickListener);
	
  
	// Prompts the user for notification permissions.
	//    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
	OneSignal.Notifications.requestPermission(true);
	

	
  }
  async getAccountInfo(){
    return (await this.general.getAccountInfo(this.selectedClient)).subscribe((res: {}) => {
      this.accountInfo = res;
    });
  }

  async getUser(){
    return (await this.general.getUser(this.selectedClient)).subscribe(async (res: {}) => {
      this.user = res;
      this.plan = this.user.user.teams.filter(i => i.id == this.selectedClient);
      await this.getTeamMembers();
    });
    
  }
  async getNavBar(){
    return (await this.general.getNavBar(this.selectedClient)).subscribe(async (res: {}) => {
      this.navbar =  res;
     
      

    });
  }

  async getTrial(){
    return (await this.general.getTrial(this.selectedClient)).subscribe((res: {}) => {
      this.trial = res;
      if(this.trial.success && this.trial?.trial<=0 && this.role=='account_owner'){
        if(this.router.url != "leads"){
          this.openUpgrade();
        }
       
      }
    });
    
  }

  logout() {
   
    
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    FB.api('/me/permissions', 'delete', null, () => FB.logout());
   
  
  }

  
  // trial ended popup

  async openUpgrade(){
    const modal = await this.modalController.create({
      component: UpgradePage,
      componentProps: {
        trialEnded : true
      },
      id: 'UpgradePopup'
      
  })
    modal.onDidDismiss().then((leadData) => {
      if (leadData.data) {
        
      }
    });
    return await modal.present();
  }
  

	

  
}
