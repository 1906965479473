


<ion-content class="ion-align-self-center  mcontent" *ngIf="!thankyou">
 
 
  <div class="bgn">

    <ion-row class=" get-sub-row" style="display:none">
     
     
      <ion-col>
        <ion-item lines="none" slot=""> 
          
        <ion-label class="annuallabel" style="float:right;font-weight:900">  {{ leadLimit }} Leads
        </ion-label>
        <ion-range aria-label="Range with ticks" [ticks]="true" [snaps]="true" [min]="0" [max]="7" (ionChange)="selectLeadLimit($event)"></ion-range>
    
      </ion-item>
      </ion-col>
      
    </ion-row>

  
    <ion-row class="no-padding mmlist">
      <ion-list lines="none">
        <ion-item *ngFor="let plan of displayPlans">
          <ion-card>
            <ion-card-content style="padding:0px">
              <ion-row>
                <img src="assets/img/gold2.gif" style="width: 90px;
                margin: auto;margin-bottom:15px">
                
              </ion-row>
              <ion-row>
                <div class="dvider"></div>
              </ion-row>
              <ion-row *ngIf="!trialEnded">
                <p class="firstp">Upgrade to Ohmylead Unlimited Subscription for limitless access and usage on all your devices. Plus, enjoy future upgrades and enhancements!</p>
              </ion-row>

              <ion-row *ngIf="trialEnded">
                <p class="ttile">🎉 Congrats on Your Pro Trial Journey!</p>
                <p class="firstp">Your Pro Plan trial has ended. It's been an amazing journey with Ohmylead! 🚀</p>
                <p class="firstp">Keep the momentum going by <b class="bold">upgrading to Pro for unlimited access and usage</b></p>
              </ion-row>

              <ion-row *ngIf="!trialEnded">
                <p class="secondp">Select your payment plan below <br> Save <b class="bold">2 Months</b> with the annual plan</p>
              </ion-row>
              <ion-row *ngIf="trialEnded">
                <p class="secondp2">Save 2 Months with Annual Plan</p>
              </ion-row>
              <ion-row>
                <div class="dvider"></div>
              </ion-row>
              <ion-row>
                <ion-col><span [class]="'annuallabel ' +activePlanMonth" style="float: right;margin-top: 8px;">Monthly</span></ion-col>
                <ion-col><ion-toggle [(ngModel)]="annual" (ionChange)='selectAnnual()' class="Awesome"> </ion-toggle></ion-col>
                <ion-col><span [class]="'annuallabel ' +activePlanYear"  style="float: left;margin-top: 8px;">Annually</span></ion-col>
              </ion-row>
                <ion-card-title>

                    <h2 style="margin-top:15px;margin-bottom:5px;text-transform: uppercase;">{{plan.name}} PLAN</h2> 
                  
                    <p class="textb">For Teams And Companies That Need to Convert More Leads.</p>

                    <span class="price" *ngIf="plan.plan_type=='year'">$<span [innerHtml]="getMonth(plan.price)"></span></span>
                    
                    <span class="price" *ngIf="plan.plan_type=='month'">${{plan.price}}</span>

                    <p class="textb2" *ngIf="plan?.plan_type =='year'">Per month billed annually for up to </p>
                    <p class="textb2" *ngIf="plan?.plan_type =='month'">Per month for up to </p>
                    <ul class="nlist">
                      <li (click)="getPlan(0)" [class.active]="limitIndex === 0"><span>5000</span></li>
                      <li (click)="getPlan(1)" [class.active]="limitIndex === 1"><span>10 K</span></li>
                      <li (click)="getPlan(2)" [class.active]="limitIndex === 2"><span>25 K</span></li>
                      <li (click)="getPlan(3)" [class.active]="limitIndex === 3"><span>50 K</span></li>
                    </ul>

                    <p class="textb2" *ngIf="plan?.plan_type =='year'">{{getMonthPrice(plan.price)}} billed monthly </p>

                    <ion-row>
                      <div class="dvider"></div>
                    </ion-row>

                    <p class="infos" *ngIf="!trialEnded">Everything in Solo Plan plus : </p>
                    <p class="subinfos" *ngIf="!trialEnded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.5" cy="6.5" r="6.5" fill="#2BC63A" fill-opacity="0.16"/>
                        <path d="M4 6.5L6 8.5L9.5 5" stroke="#2BC63A" stroke-linecap="round"/>
                      </svg>
                       Unlimited Team Members</p>
                </ion-card-title>
            </ion-card-content>
          
          
            <ion-row class="ion-padding get-sub-row" >
              <a class="subscription" expand="block" (click)="subscription(plan.product_id)">
                Upgrade now
              </a>
            </ion-row>
            <ion-row >
              <p  class="later"  (click)="cancel()">Maybe Later?</p>
            </ion-row >
          </ion-card>    
        </ion-item>
        
      </ion-list>
    </ion-row>
  </div>
 
  
</ion-content>

